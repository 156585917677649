import React, { useState } from 'react';
import { LineChart, axisClasses, lineElementClasses } from "@mui/x-charts";
import { themeColors } from '../../../theme';
import BulkMessageSender from './BulkMessageSender'; // Import the BulkMessageSender component
import SalesMetrics from '../../../helperFunctions/DashboardScenesHelpers/SalesPageHelpers/SalesMetric';
import LeadManagement from '../../../helperFunctions/DashboardScenesHelpers/SalesPageHelpers/LeadManagement';
import SalesPipeline from '../../../helperFunctions/DashboardScenesHelpers/SalesPageHelpers/SalesPipeline';
import ConvertedLeadsChart from '../../../helperFunctions/DashboardScenesHelpers/SalesPageHelpers/ConvertedLeadsChart';
import SidePanel from '../../../components/SidePanel';

const salesData = [
    { name: 'Arnav Sinha', contact: '09898989898', contacted: false, interested: false, converted: false },
    { name: 'Yash Jain', contact: 'heheheeh@gmail.com', contacted: true, interested: true, converted: true },
    { name: 'Arnav Sinha', contact: '09898989898', contacted: false, interested: false, converted: false },
    { name: 'Yash Jain', contact: 'lol@gmail.com', contacted: false, interested: false, converted: false },
    { name: 'Arnav Sinha', contact: '09898989898', contacted: true, interested: false, converted: false },
    { name: 'Yash Jain', contact: '09898989898', contacted: true, interested: true, converted: true },
    { name: 'Yash Jain', contact: 'lol@gmail.com', contacted: false, interested: false, converted: false },
    { name: 'Arnav Sinha', contact: '09898989898', contacted: true, interested: true, converted: false },
    { name: 'Yash Jain', contact: '09898989898', contacted: true, interested: true, converted: true },
];

const convertedData = [
    { name: 'Emails', leads: 40, converted: 35, color: '#cfd4c3' },
    { name: 'Marketplace', leads: 35, converted: 30, color: '#f0f8e2' },
    { name: 'LinkedIn', leads: 30, converted: 20, color: '#8ea77e' },
    { name: 'Instagram', leads: 20, converted: 15, color: '#7b8f6e' },
    { name: 'Facebook', leads: 40, converted: 30, color: '#4b573a' },
    { name: 'Others', leads: 35, converted: 25, color: '#313c2d' },
];

const overallData = { totalLeads: 100, convertedLeads: 100, salesTarget: 20000, monthlySales: 100000 }

const SalesPage = () => {
    const [isPanelExpanded, setIsPanelExpanded] = useState(false);

    const togglePanel = () => {
        setIsPanelExpanded(!isPanelExpanded);
    };

    return (
        <div className="flex flex-row w-full pl-8 pr-4">
            <div className="w-full">
                <p className="text-left text-3xl font-bold text-secondary">Sales</p>

                <div className='flex flex-col space-y-4 pt-4'>
                    <div className="flex flex-col lg:flex-row justify-between space-y-8 lg:space-y-0 lg:gap-x-8">
                        <SalesMetrics data={overallData}/>
                        <LeadManagement data={convertedData} />
                    </div>
                    <div className="flex flex-col lg:flex-row justify-between lg:gap-x-8">
                        <SalesPipeline data={salesData} />
                        <ConvertedLeadsChart data={convertedData} />
                    </div>
                </div>
            </div>

            <SidePanel isExpanded={isPanelExpanded} togglePanel={togglePanel} />
        </div>
    );
};

export default SalesPage;