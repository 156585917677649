export const cityList = 'Cities'
export const gymList = 'Gyms'
export const userList = 'Users'
export const receiptList = 'PaymentReceipts'
export const financeList = 'Finances'
export const revenueList = 'Revenue'
export const expenseList = 'Expense'
export const staffList   = 'Staff'
export const classList   = 'Classes'
export const dbCalendar = 'Calendar'
export const gymAlerts = 'Alerts'