import React, { useEffect, useState } from 'react';
import { fetchStaffList } from '../../../helperFunctions/DashboardScenesHelpers/StaffPageHelpers/staffPageHelpers';
import StaffButtons from '../../../helperFunctions/DashboardScenesHelpers/StaffPageHelpers/StaffButtons';
import { StaffDetail } from '../../../helperFunctions/DashboardScenesHelpers/StaffPageHelpers/StaffDetail';
import { AllStaff } from '../../../helperFunctions/DashboardScenesHelpers/StaffPageHelpers/AllStaff';
import AddStaff from "../../../helperFunctions/DashboardScenesHelpers/StaffPageHelpers/AddStaff";

/**
 * StaffPage Component
 * 
 * This component is responsible for managing staff-related data in the gym.
 * It includes sections for viewing staff details, adding staff, managing shifts, generating reports, and viewing staff salaries.
 * 
 * @param {Object} gymData - The data of the gym where the staff is being managed.
 */
const StaffPage = ({ gymData }) => {
    // State to manage the selected staff from the staff list
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [staffList, setStaffList] = useState([]); 

    // State to toggle the AddStaff component visibility
    const [showAddStaff, setShowAddStaff] = useState(false);

    useEffect(() => {
        const fetchStaff = async () => {
            const ListOfStaff = await fetchStaffList(gymData);
            setStaffList(ListOfStaff);
        }
        fetchStaff();
    }, [gymData]);  // Dependency array added

    /**
     * Handles staff selection from the list and sets the selected staff in state.
     * 
     * @param {Object} staff - The staff object that was clicked.
     */
    const handleStaffClick = (staff) => {
        setSelectedStaff(staff);
    };

    /**
     * Toggles the AddStaff form's visibility.
     */
    const toggleAddStaff = () => {
        setShowAddStaff((prev) => !prev);
    };

    /**
     * Placeholder function to handle shift scheduling for staff.
     * Implement the logic here as needed.
     */
    const scheduleShift = () => {
        console.log("Schedule Shift functionality");
    };

    /**
     * Placeholder function to generate reports for staff.
     * Implement the logic here as needed.
     */
    const generateReport = () => {
        console.log("Generate Report functionality");
    };

    /**
     * Placeholder function to handle staff salary management.
     * Implement the logic here as needed.
     */
    const salaries = () => {
        console.log("Salaries functionality");
    };

    return (
        <div className="flex flex-col w-full p-4 bg-tertiary">
            {/* Title */}
            <div className='text-secondary text-3xl font-semibold ml-4 mb-4'>Staff</div>

            {/* Upper section */}
            {showAddStaff ? (
                <AddStaff onBack={toggleAddStaff} gymData={gymData} />
            ) : (
                <>
                    {/* Buttons and staff details */}
                    <div className="flex flex-col lg:flex-row mb-4 gap-10 flex-grow">
                        {/* Staff action buttons: Add Staff, Schedule Shift, Generate Report, View Salaries */}
                        <div className="flex flex-row space-y-4 w-fit max-w-[25vw] flex-wrap">
                            <StaffButtons AddStaff={toggleAddStaff} scheduleShift={scheduleShift} generateReport={generateReport} salaries={salaries} />
                        </div>

                        {/* Staff details section with calendar */}
                        <div className="flex w-full justify-between rounded-2xl shadow-md p-4 border-4 border-secondary">
                            <StaffDetail
                                selectedStaff={selectedStaff} 
                                gymData={gymData} 
                            />
                        </div>
                    </div>

                    {/* Staff List */}
                    <div className="flex-grow w-full rounded-2xl p-4 mt-4 overflow-y-auto">
                        <AllStaff staffList={staffList} handleStaffClick={handleStaffClick} selectedStaff={selectedStaff} />
                    </div>
                </>
            )}
        </div>
    );
};

export default StaffPage;
