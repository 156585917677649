import { db } from '../../../config/firebase';
import { addDoc, collection, doc, getDoc, getDocs, increment, query, setDoc, updateDoc, where } from '@firebase/firestore';
import { cityList, expenseList, financeList, gymList, revenueList, userList } from '../../../components/Databases';
import { getCurrentMonth, getCurrentYear } from '../../basicHelper';
import { storeUserData } from '../../userDatabaseHelper';
import { storeGymData, storeUnknownUserInGym } from '../../gymDatabaseHelper';
import { ageChecker, amountChecker, classNameChecker, emailChecker, genderChecker, monthsChecker, phoneNumberChecker, staffNameChecker, userNameChecker } from '../../ContraintsChecker';



/**
 * This function saves added revenue in Database
 * @param {String} revenueType - Knows the Revenue Type
 * @param {Array} formDeets - An array of the form details
 * @returns {boolean} - True, if save successful; false otherwise
 */
export const saveRevenue = async (revenueType, formDeets) => {
    // Decides which alert message to display
    let myBool = false;

    // Add to total Finance
    await updateTotalFinance('Revenue', formDeets);

    // Switch Function
    // To Handle Revenue Types Differently
    switch(revenueType) {
        case 'Gym Membership' :
            // Follow these steps if user exists
            if (formDeets.userExists) {
                try {
                    // Store data in user's database
                    myBool = await storeUserData(formDeets, {
                        razorpay_payment_id : "Self"
                    });

                    // Store data in gym's Database
                    myBool = await storeGymData(formDeets, {
                        razorpay_payment_id : "Self"
                    })
                    // This stores receipts as well

                } catch (error) {
                    console.log(`Error : ${error}`);
                }
            }

            // If user doesn't exist in the database
            else {
                try {
                    // Store the user in Gym's Database
                    myBool = await storeUnknownUserInGym(formDeets);
                } catch (error) {
                    console.log('Error : ', error);
                }
            }

            // If user is not a Gym Member
            if(!formDeets.gymMember) {
                
            }

            break;

        case 'Class' :
            break;

        case 'Other' : 
            revenueType = formDeets.otherRevenueType;
            // Storing the Revenue in the Revenue Database
            myBool = await addToTotalRevenue(revenueType, formDeets);
            break;

    }

    if (myBool) {
        alert(`Revenue has been successfully added!`);
        return true;
    }
    else {
        alert('Failed to add Revenue!');
        return false;
    }
};

/**
 * Checks if User Exists.
 * 
 * @param {String} email - The email of the user to check.
 * @param {Object} gymData - Contains gym details
 * @returns {Object} - An object containing a boolean `userExists` and the `userID` if the user exists.
 */
export const checkIfUserExists = async (email, gymData) => {
    console.log('Calling checkIfUserExists');
    let userID = "";
    let userName = "";
    let gender = "";

    // Check if the user is in the gym's database
    const gymMember = await checkIfMemberExistsInGymDatabase(email, gymData);
    
    // Check if the user exists in TrainRex's Database
    const userRef = collection(db, 'user');
    const userQuery = query(userRef, where('email', '==', email));
    const userSnapshot = await getDocs(userQuery);

    if (!userSnapshot.empty) {
        const userDoc = userSnapshot.docs[0];
        const userData = userDoc.data();
        userID = userDoc.id;
        userName = userData.name;
        gender = userData.gender;
    }

    else {
        return {
            gymMember   :   gymMember
        }
    }

    return {
        userExists  : !userSnapshot.empty,
        gymMember   : gymMember,
        userID      : userID,
        userName    : userName,
        email       : email,
        gender      : gender,
    };
};

/**
 * Checks if The Members Exists in the Gym Database
 * @param {String} email - The email of the user to check.
 * @param {Object} gymData - Contains gym details
 * @returns {Boolean} - True if recurring member; false otherwise
 */
const checkIfMemberExistsInGymDatabase = async (email, gymData) => {

    // Database Handling
    const userRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, userList);
    const userQuery = query(userRef, where('email', '==', email));
    const userSnapshot = await getDocs(userQuery);

    return !userSnapshot.empty

}

/**
 * Checks if The Members Exists in the Gym Database
 * @param {String} email - The email of the user to check.
 * @param {Object} gymData - Contains gym details
 * @returns {Boolean} - True if recurring member; false otherwise
 */
export const checkIfMemberExistsInGymDatabaseUsingPhoneNumber = async (phoneNumber, gymData) => {

    // Database Handling
    const userRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, userList);
    const userQuery = query(userRef, where('phoneNumber', '==', phoneNumber));
    const userSnapshot = await getDocs(userQuery);

    return !userSnapshot.empty

}

/**
 * Saves the Revenue Data in Finances Collection of the Database
 * @param {String} revenueType 
 * @param {Object} myData 
 * @returns {Boolean} - true if storing is successful; false otherwise
 */
const addToTotalRevenue = async (revenueType, myData) => {
        
    try {
        const gymRef = collection(db, cityList, myData.city, gymList, myData.gymName, financeList, getCurrentYear(), revenueList);
        
        await addDoc(gymRef, {
            timestamp   : new Date().toISOString(),
            orderType   : revenueType,
            userName    : myData.userName,
            amount      : myData.amount,
            email       : myData.user.email,
            month       : getCurrentMonth(),
            paymentID   : "Self",
            displayName : revenueType,
            financeType : "Revenue"
        });

        return true;
    } catch (error) {
        console.log('Error : ', error);
        return false;
    }
}

/**
 * Update Total Finance Doc
 * @param {Object} myData
 * @returns {Boolean}
 */
export const updateTotalFinance = async (financeType, myData) => {
    // console.log('Calling updateTotalFinance');
    
    try {
        // Reference to the collection of finance documents for the current year
        const financeCollectionRef = collection(db, cityList, myData.city, gymList, myData.gymName, financeList);

        // Get the document reference for the current year within the finance collection
        const financeDocRef = doc(financeCollectionRef, getCurrentYear());

        // Fetch the document snapshot
        const financeDocSnapshot = await getDoc(financeDocRef);

        if (financeDocSnapshot.exists()) {
            // Get the data from the document
            const gymData = financeDocSnapshot.data();

            if (financeType === 'Revenue') {
                // Update the document with the new total revenue
                await updateDoc(financeDocRef, {
                    totalRevenue: increment(myData.amount)
                });
            } else {
                // Update the document with the new total expense
                await updateDoc(financeDocRef, {
                    totalExpense: increment(myData.amount)
                });
            }
            return true;

        } else {
            // If the document doesn't exist, create it
            if (financeType === 'Revenue') {
                await setDoc(financeDocRef, {
                    totalRevenue: myData.amount,
                    totalExpense: 0
                });
            } else {
                await setDoc(financeDocRef, {
                    totalRevenue: 0,
                    totalExpense: myData.amount
                });
            }
            
            return true;
        }

    } catch (error) {
        console.log('Error:', error);
        return false;
    }
};


export const checkValidFormDetails = (revenueType, myData) => {
    console.log('Checking for Constraints');
    switch (revenueType) {
        case 'Gym Membership' :
            return (
                ((phoneNumberChecker(myData.phoneNumber)&&       // Phone Number
                genderChecker(myData.gender) )          ||       // Gender
                myData.gymMember )                      &&       // If already a gym Member than Phone Number and Gender not needed
                userNameChecker(myData.userName)        &&       // User Name
                emailChecker(myData.email)              &&       // Email
                ageChecker(myData.age)                  &&       // Age
                monthsChecker(myData.months)            &&       // Months
                // Add a staff member checker 
                // This checks the database, if that staff exists or not
                userNameChecker(myData.staffName)       &&       // Staff Name
                amountChecker(myData.amount)            &&       // Amount
                true
            );

        case 'Classes' :
            return (
                userNameChecker(myData.userName)        &&       // User Name
                emailChecker(myData.email)              &&       // Email   
                genderChecker(myData.gender)            &&       // Gender
                ageChecker(myData.age)                  &&       // Age
                classNameChecker(myData.className)      &&       // Class Name
                amountChecker(myData.amount)            &&       // Amount
                phoneNumberChecker(myData.phoneNumber)  &&       // Phone Number
                true
            )

        default : 
            return (
                userNameChecker(myData.userName)        &&       // User Name
                emailChecker(myData.email)              &&       // Email
                amountChecker(myData.amount)            &&       // Amount
                true
            )

    }
};