/**
 * Returns the Country Code of the user
 * Based on their IP Address
 * @returns {String} - The Country Code user's IP is from
 */
export const getUserCountryCode = async () => {
    try {
        const response = await fetch('https://ipwhois.app/json/');
        const data = await response.json();
        if (data && data.country_code) {
            // console.log('User City:', data);
            return data.country_code;
        } else {
            console.error('Country not found');
            return 'US';
        }
    } catch (error) {
        console.error('Error fetching country from IP:', error.message);
        return 'US';
    }
}