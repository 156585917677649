import React, { useState, useEffect } from 'react';
import { storeCity, storeGym } from '../../helperFunctions/GymFormHelper';
import SuccessModal from '../../helperFunctions/Modal';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useLocation } from 'react-router-dom';
import { capitalizeFirstLetter, formatWordToLowerCase } from '../../helperFunctions/basicHelper';
import { updateDoc } from '@firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { formatEquipmentName } from '../../helperFunctions/GymFormHelpersExtended';

/**
 * Function to generate a random UUID
 * @returns A random ID
 */
function generateRandomUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * Function to generate a random email address
 * @returns A random Email address with domain - @trainrex.org
 */
function generateRandomEmail() {
    const domains = ['trainrex.org'];
    const username = Math.random().toString(36).substring(7);
    const domain = domains[Math.floor(Math.random() * domains.length)];
    return `${username}@${domain}`;
}

/**
 * Function to generate a random UK phone number
 * @returns A random UK phone number
 */
function generateRandomUKPhoneNumber() {
    const leadingDigits = ['74', '75', '76', '77', '78', '79'];
    const randomLeadingDigit = leadingDigits[Math.floor(Math.random() * leadingDigits.length)];
    const randomDigits = Math.random().toString().slice(2, 10);
    return `+44 ${randomLeadingDigit} ${randomDigits.slice(0, 3)} ${randomDigits.slice(3)}`;
}


/*
 * This page gets the details of the gym to enter data to the main database
 * This is used to on-board gym on the TrainRex Wesbite and App
 * @param   :   db          -   database management tool
 * @return  :   form data   -   Gym Name
 *                              Gym Locality
 *                              City
 *                              Full Name of the Owner
 */
const GymForm = () => {
   
    // Get Props from GymCred.js
    const location = useLocation();
    const { user } = location.state || {};  //  Here user is gym
   
    // /*
    //  * -------------------------------------------
    //  * ------------ THIS BLOCK OF CODE -----------
    //  * --------- GENERATES A RANDOM USER ---------
    //  * -------------------------------------------
    //  */
    // const [user, setUser] = useState({
    //     uid: '',
    //     email: '',
    //     phoneNumber: ''
    // });

    // useEffect(() => {
    //     setUser({
    //         uid: generateRandomUUID(),
    //         email: generateRandomEmail(),
    //         phoneNumber: generateRandomUKPhoneNumber()
    //     });
    // }, []); // Empty dependency array ensures this effect runs only once

    const [formData, setFormData] = useState({
        city                : '',
        gym                 : '',
        locality            : '',
        owner               : '',
        contactNo           : user.phoneNumber,
        gymContactNo        : '',
        address             : '',
        openingTime         : '',
        closingTime         : '',
        gymLogo             : '',
        email               : user.email,
        gstin               : '',
        country             : '',
        pinCode             : '',      
        dailyPrice          : '',
        monthlyPrice        : '',
        quarterlyPrice      : '',
        halfYearlyPrice     : '',
        yearlyPrice         : '',
        equipmentList       : {
            ReclineBench        :   false,
            FlatBench           :   false,
            InfiniteMachine     :   false,
            CableCrossOver      :   false,
            HexcourtMachine     :   false,
            LegExtension        :   false,
            AdopterMachine      :   false,
            SmithMachine        :   false,
            LegPress            :   false,
            Treadmill           :   false,
            StationaryBicycle   :   false,
            RowingMachine       :   false,
            ChestPress          :   false,
            Dumbells            :   false,
            SquatBar            :   false,
            PreacherCurlMachine :   false,
            Butterfly           :   false,
            CalfMachine         :   false,
            LatPullDown         :   false,
            ShoulderPress       :   false,
            BackrollMachine     :   false,
        },
        facilities          : {
            Spa             :   false,
            Sauna           :   false,
            CardioArea      :   false,
            StrengthArea    :   false,
            LoungeAndCafe   :   false,
            BoxingRing      :   false,
            MMA             :   false,
            ValetParking    :   false,
        },
        classes : {
            Zumba               :   false,
            HIIT                :   false,
            FunctionalTraining  :   false,
            CoreClasses         :   false,
            KickBoxing          :   false,
            Aerobics            :   false,
            Pilates             :   false,
        },
    });

    const [newEquipment, setNewEquipment] = useState("");
    const [newFacilities, setNewFacilities] = useState("");
    const [newClasses, setNewClasses] = useState("");
    const [imgURL, setImgURL] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
   
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    /**
     * Handles Change in CheckBoxes
     * @param {Object} e
     */
    const handleCheckboxChange = (e, listName) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [listName]: {
                ...prevData[listName],
                [name]: checked
            }
        }));
    };

    /**
     * Adds a new Equipment not listed in the list
     * @param {Object} e
     */
    const handleAddEquipment = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab' && newEquipment.trim() !== "") {
            handleCheckboxChange({
                target: {
                    name: newEquipment,
                    checked: true,
                }
            }, 'equipmentList');
            setNewEquipment("");
        }
    };

    /**
     * Adds a new Facility not listed in the list
     * @param {Object} e
     */
    const handleAddFacility = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab' && newFacilities.trim() !== "") {
            handleCheckboxChange({
                target: {
                    name: newFacilities,
                    checked: true,
                }
            }, 'facilities');
            setNewFacilities("");
        }
    };

    /**
     * Adds a new Class not listed in the list
     * @param {Object} e
     */
    const handleAddClass = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab' && newClasses.trim() !== "") {
            handleCheckboxChange({
                target: {
                    name: newClasses,
                    checked: true,
                }
            }, 'classes');
            setNewClasses("");
        }
    };


    // this will convert the image to base64string and this string will be stored in the firebase database and can be fetched along with all the data on the dashboard to display gymLogo
    const imgToURL = (e) => {
        if (!e || !e.target || !e.target.files) {
            console.error("Event or event target is undefined or missing files");
            return;
        }
   
        const file = e.target.files[0];
        if (!file) {
            console.error("No file selected");
            return;
        }
   
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
   
            reader.onloadend = () => {
                // Set the Base64 image string
                const base64Image = reader.result;
                setImgURL(base64Image);
            };
   
            // Read the image file as a data URL (Base64)
            reader.readAsDataURL(file);
        } else {
            console.error("Selected file is not an image");
        }
    };
   
    useEffect(() => {
        if (imgURL) {
            // Update the formData with the imgURL when imgURL changes
            setFormData((prevFormData) => ({ ...prevFormData, gymLogo: imgURL}));
        }
    }, [imgURL]);


    /*
     * Submits the data, and
     * Stores the data in the database
     */


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Preprocessing Data
        const info = {
            'city'              :   formatWordToLowerCase(formData.city),        // Normalize gymName by converting to lowercase and removing spaces
            'gymName'           :   formatWordToLowerCase(formData.gym),
            'gymDisplayName'    :   capitalizeFirstLetter(formData.gym),
            'locality'          :   capitalizeFirstLetter(formData.locality),
            'contactNo'         :   user.phoneNumber,
            'gymContactNo'      :   `+91${formData.gymContactNo}`,
            'owner'             :   formData.owner,
            'address'           :   capitalizeFirstLetter(formData.address),
            'openingTime'       :   formData.openingTime,
            'closingTime'       :   formData.closingTime,
            'gymLogo'           :   formData.gymLogo,
            'email'             :   user.email,
            'gstin'             :   formData.gstin,
            'country'           :   capitalizeFirstLetter(formData.country),
            'pinCode'           :   formData.pinCode,
            'dailyPrice'        :   formData.dailyPrice,
            'monthlyPrice'      :   formData.monthlyPrice,
            'quarterlyPrice'    :   formData.quarterlyPrice,
            'halfYearlyPrice'   :   formData.halfYearlyPrice,
            'yearlyPrice'       :   formData.yearlyPrice,
            'cityDisplayName'   :   capitalizeFirstLetter(formData.city),
            'equipmentList'     :   formData.equipmentList,
            'facilities'        :   formData.facilities,
            'classes'           :   formData.classes
        }


        // Storing City
        if (await storeCity(info.city))
            console.log('City Added!');
        else {
            console.log('Failed to add city');
            // The form exits upon failure
            return false;
        }


        // Storing Gym
        if (await storeGym(info, user))
            console.log('Gym Added!');
        else {
            console.log('Failed to add Gym!');
            return false;
        }


        // Adding and updating doc in website database
        // await updateProfile(user, { displayName: info.gymDisplayName });


        // Clearing the Data
        setFormData({
            city                : '',
            gym                 : '',
            locality            : '',
            owner               : '',
            contactNo           : '',
            gymContactNo        : '',
            address             : '',
            openingTime         : '',
            closingTime         : '',
            gymLogo             : '',
            email               : '',
            gstin               : '',
            country             : '',
            pinCode             : '',      
            dailyPrice          : '',
            monthlyPrice        : '',
            quarterlyPrice      : '',
            halfYearlyPrice     : '',
            yearlyPrice         : '',
            equipmentList       : {
                ReclineBench        :   false,
                FlatBench           :   false,
                InfiniteMachine     :   false,
                CableCrossOver      :   false,
                HexcourtMachine     :   false,
                LegExtension        :   false,
                AdopterMachine      :   false,
                SmithMachine        :   false,
                LegPress            :   false,
                Treadmill           :   false,
                StationaryBicycle   :   false,
                RowingMachine       :   false,
                ChestPress          :   false,
                Dumbells            :   false,
                SquatBar            :   false,
                PreacherCurlMachine :   false,
                Butterfly           :   false,
                CalfMachine         :   false,
                LatPullDown         :   false,
                ShoulderPress       :   false,
                BackrollMachine     :   false,
            },
            facilities          : {
                Spa             :   false,
                Sauna           :   false,
                CardioArea      :   false,
                StrengthArea    :   false,
                LoungeAndCafe   :   false,
                BoxingRing      :   false,
                MMA             :   false,
                ValetParking    :   false,
            },
            classes : {
                Zumba               :   false,
                HIIT                :   false,
                FunctionalTraining  :   false,
                CoreClasses         :   false,
                KickBoxing          :   false,
                Aerobics            :   false,
                Pilates             :   false,
            },
        });

        // Display Alert Message
        setModalIsOpen(true);

        return true;
       
    };

    return (
        <div className="flex flex-col bg-tertiary px-6 lg:px-56 py-32 lg:py-56">
            <h1 className="flex text-left text-xl lg:text-3xl font-bold mb-4">Gym Details</h1>
            <form onSubmit={handleSubmit}>

                {/* Section 1: Basic Information */}
                <div className="mb-8">


                    {/* Email */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Email
                        </label>
                        <input
                            type="text"
                            name="email"
                            value={user.email}
                            readOnly
                            className="w-full px-2 py-1 bg-secondary border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>



                    {/* Phone Number */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Contact Number
                        </label>
                        <input
                            placeholder="Phone Number"
                            value={user.phoneNumber}
                            readOnly
                            className="w-full px-2 py-1 bg-secondary border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"/>
                    </div>



                    <h2 className="flex text-left text-lg lg:text-2xl font-semibold mb-4">Basic Gym Information</h2>



                    {/* Textbox 1 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Name
                        </label>
                        <input
                            type="text"
                            name="gym"
                            value={formData.gym}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>



                    {/* Textbox 2 */}
                    <div className="flex flex-row mb-4 items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Address
                        </label>
                        <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>



                    {/* Textbox 3 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Locality
                        </label>
                        <input
                            type="text"
                            name="locality"
                            value={formData.locality}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>
                   
                    {/* Textbox 4 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            City
                        </label>
                        <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>



                    {/* Textbox 5 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Country
                        </label>
                        <input
                            type="text"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>




                    {/* Textbox 6 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Pin Code
                        </label>
                        <input
                            type="text"
                            name="pinCode"
                            value={formData.pinCode}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>




                    {/* Textbox 7 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            GST Number
                        </label>
                        <input
                            type="text"
                            name="gstin"
                            value={formData.gstin}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>


                    {/* Textbox 8 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Opening Time (24hr)
                        </label>
                        <input
                            type="text"
                            name="openingTime"
                            value={formData.openingTime}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>


                    {/* Textbox 9 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Closing Time (24hr)
                        </label>
                        <input
                            type="text"
                            name="closingTime"
                            value={formData.closingTime}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>

                    {/* Textbox 10 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Display Contact Number
                        </label>
                        <input
                            type="text"
                            name="gymContactNo"
                            value={formData.gymContactNo}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                            />
                    </div>
                </div>


                <div className="mb-4 flex items-center">
                    <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                        Upload Gym Logo
                    </label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={imgToURL}
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                    />
                </div>


                {/* Section 2: Owner Information */}
                <div className="mb-8">
                    <h2 className="flex text-left text-xl lg:text-2xl font-semibold mb-4">Owner Information</h2>


                    {/* Textbox 11 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Owner's Name
                        </label>
                        <input
                            type="text"
                            name="owner"
                            value={formData.owner}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>
                </div>


                {/* Section 3: Membership Information */}
                <div className="mb-8">
                    <h2 className="flex text-left text-xl lg:text-2xl font-semibold mb-4">Membership Information</h2>


                    {/* Textbox 12 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Single Day Price
                        </label>
                        <input
                            type="number"
                            name="dailyPrice"
                            value={formData.dailyPrice}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>


                    {/* Textbox 13 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Monthly Price
                        </label>
                        <input
                            type="number"
                            name="monthlyPrice"
                            value={formData.monthlyPrice}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>


                    {/* Textbox 14 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Quarterly Price
                        </label>
                        <input
                            type="number"
                            name="quarterlyPrice"
                            value={formData.quarterlyPrice}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>
                   
                    {/* Textbox 15 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Half Yealry Price
                        </label>
                        <input
                            type="number"
                            name="halfYearlyPrice"
                            value={formData.halfYearlyPrice}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>



                    {/* Textbox 16 */}
                    <div className="mb-4 flex items-center">
                        <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                            Yearly Price
                        </label>
                        <input
                            type="number"
                            name="yearlyPrice"
                            value={formData.yearlyPrice}
                            onChange={handleChange}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                        />
                    </div>
                </div>


                <div>
                    {/* Equipment List */}
                    <div className="mb-8">
                        <h2 className="flex text-left text-xl lg:text-2xl font-semibold mb-4">Equipments</h2>
                        <div className="flex flex-wrap">
                            <div className="w-1/2">
                                {Object.entries(formData.equipmentList).slice(0, Math.ceil(Object.entries(formData.equipmentList).length / 2)).map(([key, value]) => (
                                    <div key={key} className="mb-2 flex items-center">
                                        <input
                                            type="checkbox"
                                            id={key}
                                            name={key}
                                            checked={value}
                                            onChange={(e) => handleCheckboxChange(e, 'equipmentList')}
                                            className="mr-2"
                                        />
                                        <label htmlFor={key} className="text-sm lg:text-xl font-medium">{formatEquipmentName(key)}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="w-1/2">
                                {Object.entries(formData.equipmentList).slice(Math.ceil(Object.entries(formData.equipmentList).length / 2)).map(([key, value]) => (
                                    <div key={key} className="mb-2 flex items-center">
                                        <input
                                            type="checkbox"
                                            id={key}
                                            name={key}
                                            checked={value}
                                            onChange={(e) => handleCheckboxChange(e, 'equipmentList')}
                                            className="mr-2"
                                        />
                                        <label htmlFor={key} className="text-sm lg:text-xl font-medium">{formatEquipmentName(key)}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="w-1/4 mt-4">
                                <input
                                    type="text"
                                    value={newEquipment}
                                    onChange={(e) => setNewEquipment(e.target.value)}
                                    onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
                                    onKeyDown={handleAddEquipment}
                                    placeholder="Add More"
                                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                                />
                            </div>
                        </div>
                    </div>


                    {/* Facilities List */}
                    <div className="mb-8">
                        <h2 className="flex text-left text-xl lg:text-2xl font-semibold mb-4">Facilities</h2>
                        <div className="flex flex-wrap">
                            <div className="w-1/2">
                                {Object.entries(formData.facilities).slice(0, Math.ceil(Object.entries(formData.facilities).length / 2)).map(([key, value]) => (
                                    <div key={key} className="mb-2 flex items-center">
                                        <input
                                            type="checkbox"
                                            id={key}
                                            name={key}
                                            checked={value}
                                            onChange={(e) => handleCheckboxChange(e, 'facilities')}
                                            className="mr-2"
                                        />
                                        <label htmlFor={key} className="text-sm lg:text-xl font-medium">{formatEquipmentName(key)}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="w-1/2">
                                {Object.entries(formData.facilities).slice(Math.ceil(Object.entries(formData.facilities).length / 2)).map(([key, value]) => (
                                    <div key={key} className="mb-2 flex items-center">
                                        <input
                                            type="checkbox"
                                            id={key}
                                            name={key}
                                            checked={value}
                                            onChange={(e) => handleCheckboxChange(e, 'facilities')}
                                            className="mr-2"
                                        />
                                        <label htmlFor={key} className="text-sm lg:text-xl font-medium">{formatEquipmentName(key)}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="w-1/4 mt-4">
                                <input
                                    type="text"
                                    value={newFacilities}
                                    onChange={(e) => setNewFacilities(e.target.value)}
                                    onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
                                    onKeyDown={handleAddFacility}
                                    placeholder="Add More"
                                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                                />
                            </div>
                        </div>
                    </div>


                    {/* Classes List */}
                    <div className="mb-8">
                        <h2 className="flex text-left text-xl lg:text-2xl font-semibold mb-4">Classes</h2>
                        <div className="flex flex-wrap">
                            <div className="w-1/2">
                                {Object.entries(formData.classes).slice(0, Math.ceil(Object.entries(formData.classes).length / 2)).map(([key, value]) => (
                                    <div key={key} className="mb-2 flex items-center">
                                        <input
                                            type="checkbox"
                                            id={key}
                                            name={key}
                                            checked={value}
                                            onChange={(e) => handleCheckboxChange(e, 'classes')}
                                            className="mr-2"
                                        />
                                        <label htmlFor={key} className="text-sm lg:text-xl font-medium">{formatEquipmentName(key)}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="w-1/2">
                                {Object.entries(formData.classes).slice(Math.ceil(Object.entries(formData.classes).length / 2)).map(([key, value]) => (
                                    <div key={key} className="mb-2 flex items-center">
                                        <input
                                            type="checkbox"
                                            id={key}
                                            name={key}
                                            checked={value}
                                            onChange={(e) => handleCheckboxChange(e, 'classes')}
                                            className="mr-2"
                                        />
                                        <label htmlFor={key} className="text-sm lg:text-xl font-medium">{formatEquipmentName(key)}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="w-1/4 mt-4">
                                <input
                                    type="text"
                                    value={newClasses}
                                    onChange={(e) => setNewClasses(e.target.value)}
                                    onKeyDown={handleAddClass}
                                    onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
                                    placeholder="Add More"
                                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                                />
                            </div>
                        </div>
                    </div>
                </div>


                {/* Submit Button */}
                <button
                    type="submit"
                    className="bg-secondary text-white py-2 px-4 rounded-full"
                >
                    Submit
                </button>
            </form>

            <SuccessModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
        </div>
    );
};

export default GymForm;