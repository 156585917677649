import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { getMemberPaymentHistory } from "../MemberHelper";
import { FiEdit } from "react-icons/fi";
import { collection, getDocs, query, where } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import { cityList, gymList, userList } from "../../../components/Databases";

import trLogo from "../../../assets/Icon-512.png"

import { updateDoc } from "@firebase/firestore";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

/**
 * Helper function to add animation classes to the profile panel.
 * @param {HTMLElement} profilePanel - The DOM element of the profile panel.
 * @param {string} addClass - The CSS class to add.
 * @param {string} removeClass - The CSS class to remove.
 */
const addAnimationClasses = (profilePanel, addClass, removeClass) => {
  if (profilePanel) {
    profilePanel.classList.add(addClass);
    profilePanel.classList.remove(removeClass);
  }
};

/**
 * Helper function to render a profile field.
 * @param {string} label - The label of the profile field.
 * @param {string} value - The value of the profile field.
 * @returns {JSX.Element} - The rendered profile field component.
 */
const renderProfileField = (label, value) => (
  <div className="p-4 bg-tertiary rounded-lg shadow-md flex flex-col items-start">
    <span className="text-logoGray">{label}</span>
    <span className="text-lg font-medium">{value}</span>
  </div>
);

/**
 * Function to render a single receipt card.
 * @param {Object} receipt - The receipt object containing payment details.
 * @returns {JSX.Element} - The rendered receipt card component.
 */
const renderReceiptCard = (receipt, gymData) => {
  const generatePdf = (receipt) => {
    if (receipt) {
        const docDefinition = {
            content: [
                {
                    text: gymData.gymDisplayName,
                    fontSize: 20,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 10]
                },
                {
                    text: `${gymData.address,  gymData.cityDisplayName}\n` +
                          `Phone: ${gymData.contactNo} | Email: ${gymData.email}\n` +
                          `GSTIN: 07AAZFG5538L1Z2`, // HARD CODED for GYPSY FITNESS STUDIO
                    fontSize: 10,
                    alignment: 'center',
                    margin: [0, 0, 0, 20]
                },
                {
                    text: `Name: ${receipt.userName}\n` +
                          `Phone: ${receipt.userPhone}`,
                    margin: [0, 0, 0, 10]
                },
                {
                    text: `Transaction No: ${receipt.paymentID}\n` +
                          `Receipt Date: ${new Date(receipt.timestamp).toLocaleDateString()}\n` +
                          `Receipt Time: ${new Date(receipt.timestamp).toLocaleTimeString()}\n` +
                          `Payment Status: Paid`,
                    margin: [0, 0, 0, 10]
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ['auto', '*', '*', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        body: [
                            [
                                { text: 'Qty', bold: true },
                                { text: 'Membership', bold: true },
                                { text: 'Start Date', bold: true },
                                { text: 'End Date', bold: true },
                                { text: 'MRP', bold: true, alignment: 'right' },
                                { text: 'Net Price', bold: true, alignment: 'right' },
                                { text: 'CGST (9%)', bold: true, alignment: 'right' },
                                { text: 'SGST (9%)', bold: true, alignment: 'right' },
                                { text: 'Discount', bold: true, alignment: 'right' },
                                { text: 'Amount', bold: true, alignment: 'right' },
                            ],
                            [
                                { text: `${receipt.months} Months`, fontSize: 8 },
                                receipt.membershipType || '',
                                receipt.startDate || '',
                                receipt.endDate || '',
                                { text: `₹${receipt.amount/100 || '0'}`, alignment: 'right', fontSize: 8 },
                                { text: `₹${receipt.netPrice || '0'}`, alignment: 'right', fontSize: 8 },
                                { text: `₹${receipt.cgst || '0'}`, alignment: 'right', fontSize: 8 },
                                { text: `₹${receipt.sgst || '0'}`, alignment: 'right', fontSize: 8 },
                                { text: `₹${receipt.discount || '0'}`, alignment: 'right', fontSize: 8 },
                                { text: `₹${receipt.amount/100 || '0'}`, alignment: 'right', fontSize: 8 },
                            ],
                        ]
                    },
                    layout: {
                        fillColor: '#f3f3f3',
                        hLineColor: function (i, node) { return (i === 0 || i === node.table.body.length) ? 'black' : '#ccc'; },
                        vLineColor: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 'black' : '#ccc'; },
                        hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0.5; },
                        vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0.5; },
                        paddingLeft: function (i, node) { return 4; },
                        paddingRight: function (i, node) { return 4; },
                        paddingTop: function (i, node) { return 2; },
                        paddingBottom: function (i, node) { return 2; },
                    }
                },
                // {
                //     text: `Total Amount in Words: Rupees ${receipt.amountInWords || 'Zero'}`,
                //     alignment: 'right',
                //     margin: [0, 10, 0, 10]
                // },
                {
                    columns: [
                        {
                            width: '*',
                            text: [
                                `Payment Date: ${receipt.paymentDate || 'N/A'}\n`,
                                `Receipt No: ${receipt.receiptNumber || 'N/A'}\n`,
                                `Mode of Payment: ${receipt.paymentMode || 'N/A'}`
                            ],
                            margin: [0, 0, 10, 0]
                        },
                        {
                            width: 'auto',
                            text: [
                                `Total: ₹${receipt.amount/100 || '0'}\n`,
                                `Paid: ₹${receipt.amount/100 || '0'}\n`,
                                `Balance: ₹0.00`
                            ],
                            alignment: 'right'
                        }
                    ]
                },
                {
                    text: `${gymData.gymDisplayName} Terms & Conditions:`,
                    bold: true,
                    margin: [0, 20, 0, 5]
                },
                {
                    ul: [
                        'Membership rates can be revised by the management.',
                        'No membership is refundable.'
                    ]
                }
            ],
            styles: {
                tableHeader: {
                    bold: true,
                    fontSize: 12,
                    color: 'black'
                },
                tableBody: {
                    fontSize: 10
                }
            }
        };

        pdfMake.createPdf(docDefinition).open(); 
    }
};
  return(
    <div className="w-full sm:w-1/2">
    <div className="p-4 bg-white rounded-lg shadow-md mb-4 relative cursor-pointer" onClick={() => generatePdf(receipt)}> 
      <div className="flex justify-between items-center mb-2">
        <span className="text-lg font-medium">Receipt</span>
        <span className="text-sm text-logoGray">
          {new Date(receipt.timestamp).toLocaleString()}
        </span>
      </div>
      <hr className="border-tertiary my-2" />
      <div className="flex flex-col gap-1">
        <span className="text-sm text-left">
          <strong>Amount:</strong> Rs {receipt.amount / 100}
        </span>
        <span className="text-sm text-left">
          <strong>Months:</strong> {receipt.months}
        </span>
        <span className="text-sm text-left">
          <strong>Payment ID:</strong> {receipt.paymentID}
        </span>
        <span className="text-sm text-left">
          <strong>Order Type:</strong> {receipt.orderType}
        </span>
      </div>
    </div>
  </div>
  )
};

/**
 * Function to render all receipts in two columns.
 * @param {Array} receipts - The array of receipt objects to render.
 * @returns {JSX.Element} - The rendered receipts component in two columns.
 */
const renderAllReceipts = (receipts, gymData) => {
  const halfLength = Math.ceil(receipts.length / 2);
  const leftColumn = receipts.slice(0, halfLength);
  const rightColumn = receipts.slice(halfLength);



  return (
    <div className="grid grid-cols-2 gap-8">
      <div>
        {leftColumn.map((receipt, index) => (
          <div key={index} className="mb-8">
            <div className="p-4 bg-white rounded-lg shadow-md mb-4 relative">
              <div className="flex justify-between items-center mb-2">
                <span className="text-lg font-medium">Receipt</span>
                <span className="text-sm text-logoGray"> {new Date(receipt.timestamp).toLocaleString()} </span>
              </div>
              <hr className="border-tertiary my-2" />
              <div className="flex flex-col gap-1">
                <span className="text-sm text-left">
                  <strong>Amount:</strong> Rs {receipt.amount / 100}
                </span>
                <span className="text-sm text-left">
                  <strong>Months:</strong> {receipt.months}
                </span>
                <span className="text-sm text-left">
                  <strong>Payment ID:</strong> {receipt.paymentID}
                </span>
                <span className="text-sm text-left">
                  <strong>Order Type:</strong> {receipt.orderType}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        {rightColumn.map((receipt, index) => (
          <div key={index} className="mb-8">
            <div className="p-4 bg-white rounded-lg shadow-md mb-4 relative">
              <div className="flex justify-between items-center mb-2">
                <span className="text-lg font-medium">Receipt</span>
                <span className="text-sm text-logoGray">
                  {new Date(receipt.timestamp).toLocaleString()}
                </span>
              </div>
              <hr className="border-tertiary my-2" />
              <div className="flex flex-col gap-1">
                <span className="text-sm text-left">
                  <strong>Amount:</strong> Rs {receipt.amount / 100}
                </span>
                <span className="text-sm text-left">
                  <strong>Months:</strong> {receipt.months}
                </span>
                <span className="text-sm text-left">
                  <strong>Payment ID:</strong> {receipt.paymentID}
                </span>
                <span className="text-sm text-left">
                  <strong>Order Type:</strong> {receipt.orderType}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

/**
 * UserProfile Component.
 * @param {Object} member - Details of the selected member.
 * @param {Function} onClose - Callback function to close the UserProfile panel.
 * @param {Object} basicGymData - An object containing gym city and gymName.
 * @returns {JSX.Element} - The rendered UserProfile component.
 */
const UserProfile = ({ member, onClose, basicGymData, gymData }) => {
  const [receipts, setReceipts] = useState([]);
  const [showAllReceipts, setShowAllReceipts] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentMember, setCurrentMember] = useState(member);

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      const paymentHistory = await getMemberPaymentHistory(
        basicGymData,
        member.phoneNumber
      );
      setReceipts(paymentHistory);
      const total = paymentHistory.reduce(
        (sum, receipt) => sum + receipt.amount,
        0
      );
      setTotalAmount(total / 100); // Convert Paise to Rs
    };

    fetchPaymentHistory();
  }, [basicGymData, member.userID]);

  useEffect(() => {
    const profilePanel = document.getElementById("profile-panel");
    addAnimationClasses(profilePanel, "translate-x-0", "translate-x-full");

    return () => {
      addAnimationClasses(profilePanel, "translate-x-full", "translate-x-0");
    };
  }, [member]);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleInputChange = (e, field) => {
    setCurrentMember((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  console.log("member", member);

  const handleSaveChanges = async () => {
    const userRef = collection(
      db,
      cityList,
      basicGymData.city,
      gymList,
      basicGymData.gymName,
      userList
    );

    const userQuery = query(userRef, where("email", "==", member.email));
    const userSnapshot = await getDocs(userQuery);

    console.log("userSnapshot", userSnapshot);

    // Update each document that matches the query
    userSnapshot.forEach(async (doc) => {
      await updateDoc(doc.ref, currentMember);
    });

    // Exit edit mode
    setIsEditMode(false);
  };

  return (
    <>
      <CSSTransition in={!!member} timeout={300} classNames="slide">
        <div
          id="profile-panel"
          className="fixed inset-y-0 right-0 w-2/5 bg-secondary z-50 overflow-y-auto p-6 shadow-lg border-l-8 border-logoGray transform translate-x-full transition-transform duration-300"
        >
          <div className="flex justify-between items-center mb-4">
            {showAllReceipts ? (
              <button
                onClick={() => setShowAllReceipts(false)}
                className="text-primary focus:outline-none hover:text-tertiary transition text-2xl"
              >
                &larr; Back
              </button>
            ) : (
              <h3 className="text-2xl font-semibold text-primary">
                User Profile
              </h3>
            )}
            <button
              onClick={onClose}
              className="text-primary focus:outline-none hover:text-tertiary transition text-4xl"
            >
              &times;
            </button>
          </div>
          {!showAllReceipts ? (
            <>
              <div className="mb-8">
                <table className="w-full border-separate border-spacing-4">
                  <tbody>
                    <tr>
                      <td className="w-48 h-48">
                        <img
                          src={member?.profilePicture || trLogo}
                          alt="Profile"
                          className="w-full h-full rounded-full border-2 border-tertiary"
                        />
                      </td>
                      <td>
                        {isEditMode ? (
                          <div className="w-full">
                            <table className="w-full">
                              <tbody>
                                {[
                                  {
                                    label: "Username",
                                    field: "userName",
                                    value: currentMember.userName,
                                  },
                                  {
                                    label: "Age",
                                    field: "age",
                                    value: `${currentMember.age}`,
                                  },
                                  {
                                    label: "Gender",
                                    field: "gender",
                                    value: currentMember.gender,
                                  },
                                  {
                                    label: "Phone Number",
                                    field: "phoneNumber",
                                    value: currentMember.phoneNumber,
                                  },
                                  {
                                    label: "Email",
                                    field: "email",
                                    value: currentMember.email,
                                  },
                                ].map(({ label, field, value }) => (
                                  <tr key={label}>
                                    <td className="font-bold text-primary">
                                      {label}:
                                    </td>
                                    <td>
                                      <input
                                        value={value}
                                        placeholder={value}
                                        onChange={(e) =>
                                          handleInputChange(e, field)
                                        }
                                        className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-primary focus:border-primary"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="flex justify-between w-full px-20 py-3">
                              <button
                                onClick={handleSaveChanges}
                                className="bg-primary text-white py-1 px-4 rounded-md"
                              >
                                Save
                              </button>
                              <button
                                onClick={toggleEditMode}
                                className="bg-primary text-white py-1 px-4 rounded-md"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col items-start">
                            <div className="flex items-center text-2xl font-bold text-primary mb-1">
                              <span>{member.userName}</span>
                              <FiEdit
                                onClick={toggleEditMode}
                                className="ml-2 text-lg cursor-pointer"
                              />
                            </div>
                            <div className="text-sm text-logoGray mb-1">
                              {member.age} yrs | {member.gender}
                            </div>
                            <div className="text-sm text-logoGray mb-1">
                              {member.phoneNumber}
                            </div>
                            <div className="text-sm text-logoGray mb-1">
                              {member.email}
                            </div>
                            <div className="mt-2 py-1 rounded-full">
                              {member.status ? (
                                <div className="bg-logoGray text-white px-4 py-1 rounded-full">
                                  Active Member
                                </div>
                              ) : (
                                <div className="bg-red text-white px-4 py-1 rounded-full">
                                  Membership Expired
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="grid grid-cols-2 gap-4 w-full">
                {renderProfileField("Membership From", member.membershipFrom)}
                {renderProfileField("Membership Till", member.membershipTill)}
                {renderProfileField("Member Since", member.memberSince)}
                {renderProfileField("Total Amount Paid", `Rs ${totalAmount}`)}
              </div>
              <div className="mt-8">
                <div className="w-full sm:w-1/2">
                  <div className="flex justify-center mb-4">
                    <h4 className="text-xl font-semibold text-primary">
                      Payment Receipts
                    </h4>
                  </div>
                </div>
                {receipts.length > 0 && renderReceiptCard(receipts[0], gymData)}
                <div className="w-full sm:w-1/2">
                  <div className="flex justify-center mb-4">
                    <button
                      onClick={() => setShowAllReceipts(true)}
                      className="mt-4 px-4 py-2 bg-primary text-white rounded hover:bg-tertiary transition"
                    >
                      Show All Receipts
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="mt-8">
              <h4 className="text-xl font-semibold text-primary mb-4">
                All Receipts
              </h4>
              {renderAllReceipts(receipts, gymData)}
            </div>
          )}
        </div>
      </CSSTransition>
      {member && (
        <div
          className="fixed inset-0 bg-logoGray opacity-75 z-40"
          onClick={onClose}
        ></div>
      )}
    </>
  );
};

export default UserProfile;
