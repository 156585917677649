import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const LeadManagement = ({ data }) => {
  const isDataInsufficient = true; // Adjust the threshold as needed

  return (
    <div className="w-full rounded-2xl">
        <div className="flex flex-col lg:flex-row justify-between p-4 lg:p-6 rounded-2xl border-2 border-secondary">
            
            {/* Lead Management Section */}
            <div className="w-full lg:w-1/4 mb-6 lg:mb-0">
                <h2 className="text-xl font-bold text-secondary mb-4">Lead Management</h2>
                <div className="flex flex-col items-center p-6 rounded-2xl border-2 border-[#8ea77e]">
                    <div className="bg-[#e6f1d5] border-2 border-[#8ea77e] rounded-xl w-24 h-24 lg:w-32 lg:h-32 mb-4"></div>
                    <button className="w-full py-2 my-2 bg-[#8ea77e] text-white font-bold rounded-xl">
                    Add New Lead <span className="ml-2">+</span>
                    </button>
                    <button className="w-full py-2 my-2 bg-[#a8c58c] text-white font-bold rounded-xl">
                    Track Lead Status
                    </button>
                </div>
            </div>

            {/* Lead Sources Section */}
            <div className={`flex flex-col w-full lg:w-3/4 ${isDataInsufficient ? 'opacity-50 blur-sm' : ''}`}>
                <h2 className="text-xl font-bold text-secondary mb-4">Lead Sources</h2>
                <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start mr-0 lg:mr-8">
                    <ul className="flex flex-col justify-center space-y-2 items-start mb-6 lg:mb-0 lg:ml-8 lg:mt-auto w-full lg:w-auto">
                    {data.map((entry, index) => (
                        <li
                        key={`item-${index}`}
                        className="flex flex-row items-center text-secondary font-bold"
                        >
                        <span
                            className="inline-block w-4 h-4 mr-2 rounded-full"
                            style={{ backgroundColor: entry.color }}
                        ></span>
                        {entry.name} (12% | 122)
                        </li>
                    ))}
                    </ul>
                    <div className="w-full flex justify-center lg:w-auto">
                    <PieChart width={200} height={200} className="sm:hidden">
                        <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="leads"
                        >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                    <PieChart width={300} height={300} className="hidden sm:block">
                        <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={80}
                        outerRadius={140}
                        fill="#8884d8"
                        dataKey="leads"
                        >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default LeadManagement;
