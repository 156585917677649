import { doc, getDoc } from "firebase/firestore";
import { dbGym } from "../../config/firebase";
import { gymList } from "../../components/Databases";

/**
 * Gets the Gym Profile
 * @param {String} gymID - ID of the gym profile to fetch
 * @returns {Object} - Gym profile data
 */
export const getGymProfile = async (gymID) => {
    try {
        const userRef = doc(dbGym, gymList, gymID);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            const gymData = docSnap.data();
            return gymData; // Return the entire gym profile data if needed
        } else {
            console.log("No such gym document!");
            return null;
        }
    } catch (error) {
        console.error("Error fetching gym profile:", error.message);
        return null;
    }
};
