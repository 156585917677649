import React, { useState } from 'react';
import UserProfile from './UserProfile';
import { parseDate } from '../../basicHelper';

/**
 * Member Management Component
 * @param {Object} memberData - An array of objects containing member details
 * @param {Object} basicGymData - An array containing gym city and gymName
 * @param {Object} selectedMember - The currently selected member
 * @param {Function} setSelectedMember - Function to set the selected member
 */
const MemberManagement = ({ memberData, basicGymData, selectedMember, setSelectedMember, gymData }) => {
  console.log('selected Member : ');
  
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "membershipTill",
    direction: "ascending",
  });
  const [showActiveOnly, setShowActiveOnly] = useState(true);

  // Function to handle search input change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle sorting of member data
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Function to toggle showing active members only
  const handleFilterToggle = () => {
    setShowActiveOnly(!showActiveOnly);
  };

  // Function to open user profile modal
  const openUserProfile = (member) => {
    setSelectedMember(member);
  };

  // Function to close user profile modal
  const closeUserProfile = () => {
    setSelectedMember(null);
  };

  // Sorting and filtering logic
  const sortedMembers = memberData.sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Sorting by age if the key is 'age'
      if (sortConfig.key === "age") {
        return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
      }

      // Sorting by dates if the key is date-related
      if (
        sortConfig.key === "membershipFrom" ||
        sortConfig.key === "membershipTill" ||
        sortConfig.key === "memberSince"
      ) {
        const aDate = parseDate(aValue);
        const bDate = parseDate(bValue);

        if (aDate < bDate) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aDate > bDate) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      }

      // Sorting by string values (e.g., userName, gender)
      if (typeof aValue === "string" && typeof bValue === "string") {
        if (aValue.toLowerCase() < bValue.toLowerCase()) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue.toLowerCase() > bValue.toLowerCase()) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      }

      // Sorting by numeric values (e.g., age)
      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
      }
    }
    return 0;
  });

  // Filtering members based on search term and active status
  const filteredMembers = sortedMembers.filter((member) => {
    const isActive = parseDate(member.membershipTill) >= new Date();
    return (
      member.userName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (!showActiveOnly || isActive)
    );
  });

  return (
    <div className="p-4 w-full h-full flex flex-col">
      <h2 className="text-2xl font-bold mb-4">Member Management</h2>
      <div className="flex mb-4">
        <input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearch}
          className="px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
        />
        <button
          onClick={handleFilterToggle}
          className="ml-4 px-4 py-2 bg-primary text-white rounded-md"
        >
          {showActiveOnly ? "Show All" : "Show Active Only"}
        </button>
      </div>
      <div className="overflow-x-auto flex-1">
        <table className="min-w-full bg-tertiary border border-black">
          <thead>
            <tr>
              <th className="py-2 px-2 border-b border-primary text-center">
                <button onClick={() => handleSort("userName")}>
                  Name{" "}
                  {sortConfig.key === "userName"
                    ? sortConfig.direction === "ascending"
                      ? "↑"
                      : "↓"
                    : ""}
                </button>
              </th>
              <th className="py-2 px-2 border-b border-primary text-center">
                <button onClick={() => handleSort("age")}>
                  Age{" "}
                  {sortConfig.key === "age"
                    ? sortConfig.direction === "ascending"
                      ? "↑"
                      : "↓"
                    : ""}
                </button>
              </th>
              <th className="py-2 px-2 border-b border-primary text-center">
                Email
              </th>
              <th className="py-2 px-2 border-b border-primary text-center">
                Phone Number
              </th>
              <th className="py-2 px-2 border-b border-primary text-center">
                <button onClick={() => handleSort("gender")}>
                  Gender{" "}
                  {sortConfig.key === "gender"
                    ? sortConfig.direction === "ascending"
                      ? "↑"
                      : "↓"
                    : ""}
                </button>
              </th>
              <th className="py-2 px-2 border-b border-primary text-center">
                <button onClick={() => handleSort("membershipFrom")}>
                  Member From{" "}
                  {sortConfig.key === "membershipFrom"
                    ? sortConfig.direction === "ascending"
                      ? "↑"
                      : "↓"
                    : ""}
                </button>
              </th>
              <th className="py-2 px-2 border-b border-primary text-center">
                <button onClick={() => handleSort("memberSince")}>
                  Member Since{" "}
                  {sortConfig.key === "memberSince"
                    ? sortConfig.direction === "ascending"
                      ? "↑"
                      : "↓"
                    : ""}
                </button>
              </th>
              <th className="py-2 px-2 border-b border-primary text-center">
                <button onClick={() => handleSort("membershipTill")}>
                  Membership Till{" "}
                  {sortConfig.key === "membershipTill"
                    ? sortConfig.direction === "ascending"
                      ? "↑"
                      : "↓"
                    : ""}
                </button>
              </th>
              <th className="py-2 px-2 border-b border-primary text-center">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredMembers.map((member, index) => (
              <tr
                key={index}
                onClick={() => openUserProfile(member)}
                className={` cursor-pointer ${
                  index % 2 === 0 ? "bg-secondary" : "bg-tertiary"
                }`}
              >
                <td className="text-center py-2 px-2 border-b">
                  <button className="focus:outline-none">
                    {member.userName}
                  </button>
                </td>
                <td className="text-center py-2 px-2 border-b">{member.age}</td>
                <td className="text-center py-2 px-2 border-b">{member.email}</td>
                <td className="text-center py-2 px-2 border-b">{member.phoneNumber}</td>
                <td className="text-center py-2 px-2 border-b">{member.gender}</td>
                <td className="text-center py-2 px-2 border-b">{member.membershipFrom}</td>
                <td className="text-center py-2 px-2 border-b">{member.memberSince}</td>
                <td className="text-center py-2 px-2 border-b">{member.membershipTill}</td>
                <td className="text-center py-2 px-2 border-b">
                  {member.status ? (
                    <span className="px-2 py-1 bg-primary font-semibold text-white rounded-full">
                      Active
                    </span>
                  ) : (
                    <span className="px-2 py-1 bg-red font-semibold text-white rounded-full">
                      Expired
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedMember && (
        <div className="fixed inset-y-0 left-0 z-50 bg-black bg-opacity-50 flex items-center">
          <UserProfile
            member={selectedMember}
            onClose={closeUserProfile}
            basicGymData={basicGymData}
            gymData={gymData}
          />
        </div>
      )}
    </div>
  );
};

export default MemberManagement;
