import { signInWithEmailAndPassword } from "firebase/auth";
import Modal from "react-modal";
import { useState } from "react";
import { authGym } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // Importing icons from react-icons

const GymSignIn = ({ onSignIn }) => {
    console.log('GymSignIn called');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);  // New state for password visibility
    const navigate = useNavigate();

    const tryAgain = () => {
        console.log('Try Again!');
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <Modal
                isOpen={true}
                onRequestClose={() => navigate('/')}
                contentLabel="Sign In"
                className="fixed inset-0 flex items-center justify-center z-50 text-black"
                overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75"
            >
                <div className="bg-secondary rounded-lg shadow-lg p-6 sm:p-10 md:p-16 lg:p-20 w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-2xl relative flex flex-col items-center space-y-6">
                    <button
                        onClick={() => navigate('/')}
                        className="absolute top-0 right-4 text-primary hover:text-tertiary transition text-3xl sm:text-4xl"
                    >
                        &times;
                    </button>
                    <div className="flex flex-col space-y-4 w-full">
                        <h3 className="text-xl sm:text-2xl md:text-3xl text-primary font-bold text-center w-full">Gym Sign In</h3>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter Your Email"
                            className="text-primary px-4 py-2 border rounded-full bg-tertiary w-full"
                        />
                        <div className="relative w-full">
                            <input
                                type={showPassword ? "text" : "password"}  // Conditionally set input type
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter Your Password"
                                className="text-primary px-4 py-2 border rounded-full bg-tertiary w-full pr-10"  // Add padding to avoid overlap with the icon
                            />
                            <button 
                                type="button" 
                                onClick={togglePasswordVisibility}
                                className="absolute right-4 top-3 text-xl text-primary"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}  {/* Toggle between eye and eye-slash icon */}
                            </button>
                        </div>
                        <button
                            onClick={() => handleSignIn(email, password, onSignIn)}
                            className="bg-primary text-tertiary px-4 py-2 rounded-full hover:bg-tertiary hover:text-primary transition w-full sm:w-1/2 self-center"
                        >
                            Sign In
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default GymSignIn;

/**
 * Handles Sign In after user enters the credentials
 * @param {string} email
 * @param {string} password
 * @param {function} onSignIn
 */
const handleSignIn = async (email, password, onSignIn) => {
    try {
        const userCredential = await signInWithEmailAndPassword(authGym, email, password);
        const user = userCredential.user;
        console.log('User signed in:', user.uid);
        console.log('User : ', user);
        onSignIn(user);
    } catch (error) {
        console.error('Error signing in:', error.message);
        alert(`Error signing in: ${error.message}`);
    }
};
