import React, { useEffect, useState } from 'react';
import { cloud_url } from '../../../assets/links';

const BulkMessageSender = ({ users1, onClose }) => {
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');

    const users = users1[0];
    const key = users1[1];

    const handleSendPersonalizedMessage = async (sendMethod) => {
        try {
            for (const user of users) {
                const personalizedMessage = `Hi ${user.name}, ${message}`;
                const response = await fetch(`${cloud_url}/send_bulk_${sendMethod}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ numbers: [user.number], subject: subject, message: personalizedMessage })
                });
                
                if (response.ok) {
                    console.log(`${sendMethod.toUpperCase()} MSG SENT to ${user.name}`);
                } else {
                    const errorMessage = await response.text();
                    console.error(`Failed to fetch data from the server. Status: ${response.status}, Error: ${errorMessage}`);
                }
            }
        } catch (error) {
            alert(`Failed to send ${sendMethod} messages`);
        }
    };

    const handleSendSMS = () => handleSendPersonalizedMessage('sms');
    const handleSendWhatsApp = () => handleSendPersonalizedMessage('whatsapp');
    const handleSendEmail = () => handleSendPersonalizedMessage('email');

    return (
        <div id="ContactUs" className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <form className="bg-tertiary w-full h-auto lg:w-1/2 rounded-lg p-8">
                <h2 className="text-2xl font-semibold text-primary">Sending messages to all leads from Marketplace</h2>
                <div className="flex flex-col w-full space-y-4">
                    {key === 'Email' && <textarea 
                        rows="1" 
                        placeholder="Subject" 
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />}
                    <textarea 
                        rows="4" 
                        placeholder="Enter message" 
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </div>

                {key === 'Phone' && 
                <>
                    <button type="button" onClick={handleSendSMS} className="bg-secondary text-tertiary py-2 px-4 rounded-2xl">
                        Send SMS
                    </button>
                    <button type="button" onClick={handleSendWhatsApp} className="ml-4 bg-secondary text-white py-2 px-4 rounded-2xl">
                        Send WhatsApp
                    </button>
                </>}
                {key === 'Email' && 
                <>
                    <button type="button" onClick={handleSendEmail} className="bg-secondary text-tertiary py-2 px-4 rounded-2xl">
                        Send Email
                    </button>
                    </>}
                    <button type="button" onClick={onClose} className="ml-4 bg-secondary text-white py-2 px-4 rounded-2xl">
                        Close
                    </button>
            </form>
        </div>
    );
};

export default BulkMessageSender;
