/**
 * Phone Number Constraint
 * @param {String} phoneNumber 
 * @param {String} userCountry  -   Country Code (Default is India)
 * @returns {Bool}
 */
export const phoneNumberChecker = (phoneNumber, userCountry = 'IN') => {
    if (!phoneNumber) {
        console.log("Phone number is undefined or empty");
        return false;
    }

    const countryLength = {
        'IN': 13,
        'GB': 13,
        'US': 12,
    };

    if (phoneNumber.length !== countryLength[userCountry]) {
        return false;
    }

    return true;
};

/**
 * User Name Constraint
 * @param {String} userName
 * @returns {Bool}
 */
export const userNameChecker = (userName) => {
    console.log("Error in useNameChecker");
    if (userName.trim() === '') {
        return false;
    }
    return true;
};

/**
 * Gender Constraint
 * @param {String} gender
 * @returns {Bool}
 */
export const genderChecker = (gender) => {
    console.log("Error in genderChecker");
    if (gender === '') {
        return false;
    }
    return true;
};

/**
 * Amount Constraint
 * @param {Number} amount
 * @returns {Bool}
 */
export const amountChecker = (amount) => {
    console.log("Error in amountChecker");
    if (amount < 0) {
        return false;
    }
    return true;
};

/**
 * Age Constraint
 * @param {Number} age
 * @returns {Bool}
 */
export const ageChecker = (age) => {
    console.log("Error in ageChecker");
    if (age >= 0 && age <= 100) {
        return true;
    }
    return false;
};

/**
 * Class Name Constraint
 * @param {String} className 
 * @returns {Bool} - true if valid, false if invalid
 */
export const classNameChecker = (className) => {
    console.log("Error in classNemeChecker");
    // Check if class name is not empty, doesn't contain only spaces, and has a reasonable length
    return className.trim() !== '' && className.length > 1 && className.length <= 100;
};

/**
 * Checks for Staff's Position
 * Shouldn't be Default
 * @param {String} position 
 * @returns {Bool}
 */
export const positionChecker = (position) => {
    console.log("Error in positionChekcker");
    if (position == 'Default' || position.toLowerCase() =='select a role')
        return false;
    return true;
}

/**
 * Date Constraint
 * @param {String} date - in format 'YYYY-MM-DD'
 * @returns {Bool} - true if valid, false if invalid
 */
export const dateChecker = (date) => {
    console.log("Error in dateChecker");
    const today = new Date().setHours(0, 0, 0, 0);
    const selectedDate = new Date(date).setHours(0, 0, 0, 0);
    
    // Check if the date is a valid date and is not in the past
    return !isNaN(selectedDate) && selectedDate >= today;
};

/**
 * Time Constraint
 * @param {String} time - in format 'HH:MM' (24-hour format)
 * @returns {Bool} - true if valid, false if invalid
 */
export const timeChecker = (time) => {
    console.log("Error in timeChecker");
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

    // Check if time matches the 24-hour format HH:MM
    return timeRegex.test(time);
};


/**
 * Email Constraint
 * @param {String} email 
 * @returns {Bool} - true if valid, false if invalid
 */
export const emailChecker = (email) => {
    console.log("Error in emailChecker");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email matches the standard email format
    return emailRegex.test(email);
};

/**
 * Months Constraint
 * @param {Number} months 
 * @returns {Bool} - true if valid, false if invalid
 */
export const monthsChecker = (months) => {
    console.log("Error in monthsChecker");
    if (typeof months !== 'number' || months < 0) {
        return false;
    }
    return true;
};