import React, { useEffect, useState } from 'react';
import { checkIfUserExists, checkValidFormDetails, saveRevenue } from './AddRevenueHelper';
import { fetchClasses, fetchClassesOfDate } from '../ClassesHelpers/fetchClassData';
import { capitalizeFirstLetter, getDate } from '../../basicHelper';

/**
 * AddRevenue component for adding revenue details.
 * @param {Function} onBackClick - handles back button
 * @param {Object} gymData - has gymDetails
 * @param {string} lastPage - determines the context of the page (default 'Finance')
 * @returns {JSX.Element} - The rendered AddRevenue component.
 */
const AddRevenue = ({ onBackClick, gymData, lastPage = 'Finance' }) => {
    const [revenueType, setRevenueType] = useState(lastPage === 'Clients' ? 'Gym Membership' : '');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [userExists, setUserExists] = useState(false);
    const [gymMember, setGymMember] = useState(false);
    const [userInfo, setUserInfo] = useState({ userName: '', age: '', gender: '' });
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [months, setMonths] = useState(1);
    const [amount, setAmount] = useState('');
    const [hasTrainer, setHasTrainer] = useState(false);
    const [trainerName, setTrainerName] = useState('');
    const [className, setClassName] = useState('');
    const [classDate, setClassDate] = useState('');
    const [classTime, setClassTime] = useState('');
    const [otherRevenueType, setOtherRevenueType] = useState('');
    const [classOptions, setClassOptions] = useState([]);
    const [staffName, setStaffName] = useState('');
    const [remarks, setRemarks] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [startDate, setStartDate] = useState(''); //added this field to set a custom join date

    const resetForm = () => {
        setRevenueType(lastPage === 'Clients' ? 'Gym Membership' : '');
        setEmail('');
        setUserExists(false);
        setUserInfo({ userName: '', age: '', gender: '' });
        setName('');
        setAge('');
        setGender('');
        setMonths(1);
        setStartDate('');
        setAmount('');
        setHasTrainer(false);
        setTrainerName('');
        setClassName('');
        setClassDate('');
        setClassTime('');
        setOtherRevenueType('');
        setPhoneNumber('');
        setDateOfBirth('');
        setStaffName('');
        setRemarks('');
        setIsChecked(false);
    };

    const handleCheckUser = async () => {
        const userResult = await checkIfUserExists(email, gymData);
        setUserExists(userResult.userExists);
        setIsChecked(true);
        if (userResult.userExists) {
            setUserInfo(userResult);
        } else {
            alert(`User (${email}) does not exist!`);
        }
        setGymMember(userResult.gymMember);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let theUserName = '';
        if (userInfo.userName)
            theUserName = capitalizeFirstLetter(userInfo.userName);
        else 
            theUserName = capitalizeFirstLetter(name);

        const formDetails = {
            ...gymData,
            userExists,
            email,
            userName        : theUserName,
            age             : userInfo.age || age,
            gender          : userInfo.gender || gender,
            startDate,
            months          : parseInt(months),
            amount          : amount * 100,
            hasTrainer,
            trainerName,
            className,
            classDate,
            classTime,
            user            : {
                email       : email,
                uid         : userInfo.userID,
                displayName : theUserName,
            },
            phoneNumber     : `+91${phoneNumber}`,
            dateOfBirth,
            otherRevenueType,
            gymMember,
            staffName       :   capitalizeFirstLetter(staffName),
            remarks,
        };


        if (!checkValidFormDetails(revenueType, formDetails)) {
            alert('Please fill all the details before submitting!');
        }
        else {

            const isSuccess = await saveRevenue(revenueType, formDetails);

            if (isSuccess) {
                resetForm();
                onBackClick();
            }
        }
    };

    useEffect(() => {
        const loadClasses = async () => {
            try {
                // const classes = await fetchClassesOfDate(gymData, getDate());
                const classes = await fetchClasses(gymData);
                setClassOptions(classes);
                console.log('classOptions : ', classOptions);
            } catch (error) {
                console.error('Error fetching classes:', error);
            }
        };
        loadClasses();
    }, [gymData]);

    // Handle the date change and format it
    const handleDateChange = (e) => {
        const formattedDate = formatDateToDDMMYYYY(e.target.value);  // Format as DD-MM-YYYY
        setStartDate(formattedDate);  // Set the formatted date
    };

    return (
        <div className="p-8">
            <div className="w-full flex justify-start mb-6">
                <button
                    onClick={onBackClick}
                    className="bg-secondary text-white py-2 px-4 rounded hover:bg-primary"
                >
                    Back
                </button>
            </div>
            <div className="flex justify-center">
                <div className="w-full max-w-md text-left">
                    <h2 className="text-2xl mb-6 text-center">
                        {lastPage === 'Clients' ? 'Add Member' : 'Add Revenue'}
                    </h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {lastPage !== 'Clients' && (
                            <div>
                                <label className="block mb-2">Revenue Type</label>
                                <select
                                    className="p-2 border rounded w-full"
                                    value={revenueType}
                                    onChange={(e) => {
                                        setRevenueType(e.target.value);
                                        if (e.target.value === 'Other') {
                                            setIsChecked(true);
                                        }
                                    }}
                                    required
                                >
                                    <option value="">Select Revenue Type</option>
                                    <option value="Gym Membership">Gym Membership</option>
                                    <option value="Class">Class</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        )}

                        {/* Email ID and Check Button */}
                        {(revenueType === 'Gym Membership' || revenueType === 'Class') && (
                            <div>
                                <label className="block mb-2">Email ID</label>
                                <div className="flex">
                                    <input
                                        type="email"
                                        className="p-2 border rounded w-full"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={handleCheckUser}
                                        className="bg-secondary text-white ml-2 py-2 px-4 rounded hover:bg-primary"
                                    >
                                        Check
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Render the rest of the form only after user check or if revenueType is 'Other' */}
                        {(isChecked || revenueType === 'Other') && (
                            <>
                                {userExists && gymMember && (
                                    <>
                                        <div>
                                            <label className="block mb-2">Name</label>
                                            <input
                                                type="text"
                                                className="p-2 border rounded w-full"
                                                value={userInfo.userName}
                                                readOnly
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Gender</label>
                                            <input
                                                type="text"
                                                className="p-2 border rounded w-full"
                                                value={userInfo.gender}
                                                readOnly
                                            />
                                        </div>
                                    </>
                                )}

                                {userExists && !gymMember && (
                                    <>
                                        <div>
                                            <label className="block mb-2">Name</label>
                                            <input
                                                type="text"
                                                className="p-2 border rounded w-full"
                                                value={userInfo.userName}
                                                readOnly
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Date of Birth</label>
                                            <input
                                                type="date"
                                                className="p-2 border rounded w-full"
                                                value={dateOfBirth}
                                                onChange={(e) => setDateOfBirth(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Phone Number</label>
                                            <input
                                                type="text"
                                                className="p-2 border rounded w-full"
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </>
                                )}

                                {!userExists && revenueType !== 'Other' && (
                                    <>
                                        <div>
                                            <label className="block mb-2">Name</label>
                                            <input
                                                type="text"
                                                className="p-2 border rounded w-full"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Age</label>
                                            <input
                                                type="number"
                                                className="p-2 border rounded w-full"
                                                value={age}
                                                onChange={(e) => setAge(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {!gymMember && (
                                            <> 
                                                <div>
                                                    <label className="block mb-2">Gender</label>
                                                    <select
                                                        className="p-2 border rounded w-full"
                                                        value={gender}
                                                        onChange={(e) => setGender(e.target.value)}
                                                        required
                                                    >
                                                        <option value="">Select Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label className="block mb-2">Phone Number</label>
                                                    <input
                                                        type="text"
                                                        className="p-2 border rounded w-full"
                                                        value={phoneNumber}
                                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <label className="block mb-2">Date of Birth</label>
                                                    <input
                                                        type="date"
                                                        className="p-2 border rounded w-full"
                                                        value={dateOfBirth}
                                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}

                                <div>
                                    <label className="block mb-2">Start Date</label>
                                    <input
                                        type="date"
                                        className="p-2 border rounded w-full"
                                        value={formatDateToYYYYMMDD(startDate)} // Format as YYYY-MM-DD for input
                                        onChange={handleDateChange}
                                        required
                                    />
                                </div>

                                {revenueType === 'Gym Membership' && (
                                    <>
                                        <div>
                                            <label className="block mb-2">Months</label>
                                            <input
                                                type="number"
                                                className="p-2 border rounded w-full"
                                                value={months}
                                                onChange={(e) => setMonths(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Amount (Rs)</label>
                                            <input
                                                type="number"
                                                className="p-2 border rounded w-full"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Want Personal Trainer?</label>
                                            <div>
                                                <label className="mr-4">
                                                    <input
                                                        type="checkbox"
                                                        checked={hasTrainer}
                                                        onChange={(e) => setHasTrainer(e.target.checked)}
                                                    />
                                                    <span className="ml-2">Yes</span>
                                                </label>
                                                {hasTrainer && (
                                                    <input
                                                        type="text"
                                                        className="p-2 border rounded w-full mt-2"
                                                        placeholder="Trainer Name"
                                                        value={trainerName}
                                                        onChange={(e) => setTrainerName(e.target.value)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}

                                {revenueType === 'Class' && (
                                    <>
                                        <div>
                                            <label className="block mb-2">Class Name</label>
                                            <select
                                                className="p-2 border rounded w-full"
                                                value={className}
                                                onChange={(e) => setClassName(e.target.value)}
                                                required
                                            >
                                                <option value="">Select Class</option>
                                                {classOptions.map((classOption, index) => (
                                                    <option key={index} value={classOption.className}>
                                                        {classOption.className}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label className="block mb-2">Class Date</label>
                                            <input
                                                type="date"
                                                className="p-2 border rounded w-full"
                                                value={classDate}
                                                onChange={(e) => setClassDate(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Class Time</label>
                                            <input
                                                type="time"
                                                className="p-2 border rounded w-full"
                                                value={classTime}
                                                onChange={(e) => setClassTime(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Amount (Rs)</label>
                                            <input
                                                type="number"
                                                className="p-2 border rounded w-full"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </>
                                )}

                                {(revenueType === 'Gym Membership' || revenueType === 'Class') && (
                                    <>
                                        <div>
                                            <label className="block mb-2">Lead By (Staff Name)</label>
                                            <input
                                                type="text"
                                                className="p-2 border rounded w-full"
                                                value={staffName}
                                                onChange={(e) => setStaffName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Remarks</label>
                                            <textarea
                                                className="p-2 border rounded w-full"
                                                value={remarks}
                                                onChange={(e) => setRemarks(e.target.value)}
                                                rows="3"
                                            ></textarea>
                                        </div>
                                    </>
                                )}

                                {revenueType === 'Other' && (
                                    <>
                                        <div>
                                            <label className="block mb-2">Other Revenue Type</label>
                                            <input
                                                type="text"
                                                className="p-2 border rounded w-full"
                                                value={otherRevenueType}
                                                onChange={(e) => setOtherRevenueType(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Name</label>
                                            <input
                                                type="text"
                                                className="p-2 border rounded w-full"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Email ID</label>
                                            <input
                                                type="email"
                                                className="p-2 border rounded w-full"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Amount (Rs)</label>
                                            <input
                                                type="number"
                                                className="p-2 border rounded w-full"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </>
                                )}

                                <div className="flex space-x-4">
                                    <button
                                        type="submit"
                                        className="bg-secondary text-white py-2 px-4 rounded hover:bg-primary"
                                    >
                                        Submit
                                    </button>
                                    <button
                                        type="button"
                                        onClick={resetForm}
                                        className="bg-secondary text-white py-2 px-4 rounded hover:bg-primary"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddRevenue;


// Helper function to format date as DD-MM-YYYY
const formatDateToDDMMYYYY = (dateString) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
};

// Helper function to format date to YYYY-MM-DD (required by input type="date")
const formatDateToYYYYMMDD = (dateString) => {
    if (!dateString) return '';
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
};