import React, { useEffect, useState, useTransition } from 'react';
import { Route, useLocation, Outlet, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Dashboard from './Screens/B2BDashboard/Dashboard';
import GymForm from './Screens/B2BDashboard/GymForm';
import GymCred from './Screens/B2BDashboard/GymCred';
import GymsDashboard from './Screens/B2BDashboard/GymsDashboard';

// Import Components
import BottomBar from "./components/BottomBar";
import { analytics } from "./config/firebase";
import { logEvent } from "firebase/analytics";
import './index.css'; // Import the global stylesheet
import BulkMessageSender from './Screens/B2BDashboard/DashBoardScenes/BulkMessageSender';
import TCPage from './Screens/TCPage';
import PPPage from './Screens/PPPage';

function App() {
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    const isGymsDashboard = location.pathname === '/GymsDashboard';
    
    const [userCity, setUserCity] = useState(null);

    useEffect(() => {
        // Log an event when the user enters the app
        // Capture the user's browser type
        const currentTime = new Date().toISOString();
        const userAgent = navigator.userAgent;
        logEvent(analytics, `${userAgent}, ${currentTime}`);
    }, []);

    return (
        <div className="App">
            <ToastContainer bodyClassName="toastBody" progressClassName="toastProgress" />
            <Routes>
                <Route path="/Dashboard" element={<Dashboard />} />
                <Route path="/GymForm" element={<GymForm />} />
                <Route path="/GymCred" element={<GymCred />} />
                <Route path="/" element={<GymsDashboard />} />
                <Route path="/BulkMessageSender" element={<BulkMessageSender />} />
                <Route path="/TCPage" element={<TCPage />} />
                <Route path="/PPPage" element={<PPPage />} />
            </Routes>
            {/* Conditionally render the BottomBar */}
            {!isGymsDashboard && <BottomBar />}
        </div>
    );
}

export default App;
