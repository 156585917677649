import React from 'react';

const SalesPipeline = ({ data }) => {
  return (
    <div className="flex flex-col pt-2 w-full lg:w-1/2">
        <div className="flex flex-row justify-between">
            <p className="text-lg text-secondary font-bold text-left p-2">Sales Pipeline</p>
        </div>
        <div className="flex flex-col pb-4 rounded-3xl border-secondary border-2 py-2 px-2">
            <div className="overflow-x-auto">
                <table className="min-w-full bg-tertiary">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b-2 border-secondary text-center text-secondary text-sm"></th>
                            <th className="py-2 px-4 border-b-2 border-secondary text-center text-secondary text-sm">Full Name</th>
                            <th className="py-2 px-4 border-b-2 border-secondary text-center text-secondary text-sm">Contact</th>
                            <th className="py-2 px-4 border-b-2 border-secondary text-center text-secondary text-sm">Contacted</th>
                            <th className="py-2 px-4 border-b-2 border-secondary text-center text-secondary text-sm">Interested</th>
                            <th className="py-2 px-4 border-b-2 border-secondary text-center text-secondary text-sm">Converted</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                        <tr key={index}>
                            <td className="py-2 px-4 border-b text-center text-sm font-semibold">{index + 1}</td>
                            <td className="py-2 px-4 border-b text-center text-sm font-semibold">{item.name}</td>
                            <td className="py-2 px-4 border-b text-center text-sm font-semibold">{item.contact}</td>
                            <td className="py-2 px-4 border-b text-center text-sm font-semibold">
                                {item.contacted ? '✔️' : '-'}
                            </td>
                            <td className="py-2 px-4 border-b text-center text-sm font-semibold">
                                {item.interested ? '✔️' : '-'}
                            </td>
                            <td className="py-2 px-4 border-b text-center text-sm font-semibold">
                                {item.converted ? '✔️' : '-'}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="h-0.5 bg-secondary rounded-full mt-1"></div>
            <div className="text-center mt-2 text-secondary font-semibold cursor-pointer hover:underline">
                See more
            </div>
        </div>
    </div>
  );
};

export default SalesPipeline;
