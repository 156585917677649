import React from "react";
import { collection, getDocs, query, where } from "@firebase/firestore";
import { db } from "../../config/firebase";
import { cityList, gymList, financeList, revenueList, userList } from "../../components/Databases";
import { getCurrentYear } from "../basicHelper";

/**
 * Gets the list of gym's members
 * @param {Object} gymData  - An object containing:
 *                          - city (String): The city where the gym is located
 *                          - gymName (String): The name of the gym
 * @returns {Array} - A list of user objects where 'userID' is not '000'
 */
export const getMyData = async(gymData) => {
  try {
    // Create a reference to the gym's user collection
    const gymRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, userList);
    
    // Create a query to find users where 'userName' is not 'Owner'
    // As it is a default user added by firebase
    const userQuery = query(gymRef, where('userName', '!=', 'Owner'));
    
    // Execute the query and get the snapshot of documents
    const querySnapshot = await getDocs(userQuery);
    
    // Initialize an array to hold the user data
    const users = [];
    
    // Iterate through the query results and push each user's data to the users array
    querySnapshot.forEach((doc) => {
      users.push(doc.data());
      console.log(doc.data());
    });
    
    // Return the list of users
    return users;

  } catch (error) {
    console.error('Error getting gym members:', error);
    throw new Error('Unable to retrieve gym members');
  }
  
}

/**
 * Gets Member's Payment History
 * @param {Object} basicGymData - An object containing city and gymName
 * @param {String} userID - The user's unique identifier
 * @returns {Array} - An array of receipt documents
 */
export const getMemberPaymentHistory = async (basicGymData, phoneNumber) => {
  try {
    // Reference to the user's payment receipts collection in the database
    const userRef = collection(db, cityList, basicGymData.city, gymList, basicGymData.gymName, financeList, getCurrentYear(), revenueList);

    // Query to get all receipts for the given userID
    const userQuery = query(userRef, where('phoneNumber', '==', phoneNumber));

    // Execute the query and get the snapshot of documents
    const userSnapshot = await getDocs(userQuery);

    // Initialize an array to hold all receipt documents
    let receiptDocs = [];

    // If payment receipts exist, add them to the array
    if (!userSnapshot.empty) {
      userSnapshot.forEach((doc) => {
        receiptDocs.push(doc.data());
      });
    }

    // Return the array of receipt documents
    return receiptDocs;
  } catch (error) {
    console.error("Error getting payment history:", error);
    return [];
  }
}

